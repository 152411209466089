/* eslint-disable lines-between-class-members */
import { reportError } from 'config/sentry';
import { createContext } from 'react';
import { action, computed, observable } from 'mobx';
import merge from 'deepmerge';
import moment from 'moment';
import { getCourseInfo, getCourseInfoMeta } from 'services/ClassroomService';
import CatalogStore from 'stores/Catalog';
import {
  getCoursePage,
  getCourseStructure,
  getCourseOffering,
  createUpdateCourseBookmark,
  getCourseMetadata,
} from 'services/CourseService';
import { postEvent } from 'services/EventService';
import {
  getCourseEnrollment,
  getEnrollmentInstance,
  saveEnrollmentUserData,
} from 'services/UserEnrollmentService';
import { ZDOC_COURSE_INFO } from 'config/constants';
import ZDocManagerStore from 'stores/ZdocManager';
import { CourseFormat, CourseType, Modality } from 'types';
import reverse from 'lodash/reverse';

const dateToTimestamp = (date) => {
  const dateObj = date ? moment(date) : moment();
  return dateObj.toISOString();
};

class CourseStore {
  @observable isLoadingInitialData = true;
  @observable loadedInitialData = false;
  @observable initialLoadError = false;
  @observable initialErrorCount = 0; // number of times the initial data fetch has failed. Useful for UI.

  @observable isLoadingComplementaryData = true;
  @observable loadedComplementaryData = false;
  @observable complementaryDataLoadError = false;

  @observable isLoadingPage = true;
  @observable loadedPage = false;
  @observable pageFetchError = false;

  // base course info:
  @observable courseSlug = null;
  @observable courseInfo = null;
  @observable courseInfoMeta = null;
  @observable courseOffering = null; // contains expert extras and lab plus list
  @observable courseCode = null;
  @observable courseVersion = null;
  @observable courseCatalogEntry = null;
  @observable courseMetadata = null; // lessons only
  @observable translations = null;
  @observable versions = [];

  // enrollment info:
  @observable euuid = null;
  @observable enrollment = null;

  @observable courseProgress = {};
  @observable courseProgressLoaded = false;

  // page videos:
  @observable videosInCourse = null;

  // complementary data based on language:
  @observable courseStructure = null;
  @observable pageSlug = null;
  @observable page = null; // page content

  // other settings
  @observable languageOverride;
  @observable offeringSettings = {};

  constructor(
    routerStore,
    uiStore,
    userStore,
    catalogStore,
    progressStore,
    classesStore,
    localLabStore,
    eventStore,
    configStore,
  ) {
    this.routerStore = routerStore;
    this.uiStore = uiStore;
    this.userStore = userStore;
    this.catalogStore = catalogStore;
    this.progressStore = progressStore;
    this.classesStore = classesStore;
    this.localLabStore = localLabStore;
    this.eventStore = eventStore;
    this.configStore = configStore;

    this.zdocManagerStore = new ZDocManagerStore(ZDOC_COURSE_INFO);
  }

  @computed get language() {
    return (
      this.languageOverride ||
      this.routerStore?.route?.params?.language ||
      this.userData?.language ||
      this.uiStore.currentLanguage ||
      'en-US'
    );
  }

  /**
   * Indicates the last page that the user was on.
   * If the user has a bookmark saved on enrollment.user_Data, it will be used.
   * Otherwise, it will fallback to the first page of the course.
   */
  static getLastBookmarkedPage(currentCourseSlug, enrollment, structure) {
    // get user last bookmark:
    const lastBookmark = enrollment?.user_data?.bookmark;
    if (lastBookmark && lastBookmark !== currentCourseSlug) {
      return lastBookmark;
    }

    // fallback to first page:
    return structure?.[0]?.page_tag || 'ch01';
  }

  @computed get lastBookmarkedPage() {
    return CourseStore.getLastBookmarkedPage(
      this.courseSlug,
      this.enrollment,
      this.courseStructure,
    );
  }

  /**
   * Returns the course info for the most current version of the course.
   * @param {object} courseInfo
   * @param {string} language
   */
  static getCurrentCourse(courseInfo, language = 'en-US') {
    const { dynamic_info: dynamicInfo } = courseInfo || {};

    const found = dynamicInfo?.summaries.find(
      (course) =>
        course.version === dynamicInfo.current && course.language === language,
    );

    const defaultCourse = dynamicInfo?.summaries.find(
      (course) =>
        course.version === dynamicInfo.current && course.language === 'en-US',
    );

    return found || defaultCourse || {};
  }

  static isBundle(catalogEntry) {
    return catalogEntry?.format === CourseFormat.Bundle;
  }

  /**
   * Fetches the list of videos for all pages in a course
   * @returns {Promise} - Returns a promise that resolves to the list of videos for all pages in a course
   */
  @action async getPageVideosForCourse() {
    try {
      this.zdocManagerStore.terms = { _id: this.courseSlug };
      const _courseInfoDocs = await this.zdocManagerStore.fetch();

      this.videosInCourse =
        _courseInfoDocs && _courseInfoDocs.length >= 1
          ? _courseInfoDocs[0]
          : {};
    } catch (e) {
      this.videosInCourse = {};
      console.error(
        'Error while getting list of videos for all pages for a course',
        e,
      );
    } finally {
      return this.videosInCourse;
    }
  }

  /**
   * Fetches the course structure for the current course.
   * @returns {Promise} - Returns a promise that resolves to the course structure
   */
  @action getCourseStructure = async () => {
    const structure = await getCourseStructure(this.courseSlug, this.language);
    this.courseStructure = structure || this.courseStructure;

    return this.courseStructure;
  };

  /**
   * Fetches the page content for the current course.
   * requires courseSlug and pageSlug to be set before calling this method
   * @returns {Promise} - Returns a promise that resolves to the page content
   */
  @action getCoursePage = async () => {
    if (this.courseSlug && this.pageSlug) {
      try {
        this.isLoadingPage = true;

        const page = await getCoursePage(
          this.courseSlug,
          this.pageSlug,
          this.language,
        );

        if (!page) {
          this.isLoadingPage = false;
          return {};
        }

        this.page = page;
        this.loadedPage = true;
      } catch (fetchPageError) {
        console.error(fetchPageError);
        this.pageFetchError = true;
      } finally {
        this.isLoadingPage = false;
      }
    }
    return this.page;
  };

  /**
   * Changes the page content for the current course, changing the URL accordingly. It also saves the user's progress in that page.
   * @param {string} pageId - The page slug to navigate to
   */
  @action changePageContent = async (pageId) => {
    if (!pageId) {
      this.isLoadingPage = false;
      return;
    }
    this.pageSlug = pageId;

    if (this.routerStore?.route?.params?.page !== this.pageSlug) {
      this.routerStore?.navigate('courses:page', {
        course: this.courseSlug,
        page: this.pageSlug,
      });
    }

    const coursePage = await this.getCoursePage(); // updates this.page property

    if (!coursePage?.uuid) return;

    // allSettled always resolves, even if some promises reject
    const resolution = await Promise.allSettled([
      this.saveEnrollmentUserData(),
      this.progressStore.setCourseProgress(this.courseSlug, coursePage.uuid),
    ]);

    if (resolution[0]?.status === 'rejected') {
      console.error('Failed saving enrollment user data', resolution[0].reason);
    }

    if (resolution[1]?.status === 'rejected') {
      console.error(
        'Failed saving and getting page progress',
        resolution[1].reason,
      );
    } else if (
      resolution[1]?.status === 'fulfilled' &&
      resolution[1].value?.rv
    ) {
      // Save progress locally
      this.courseProgress = resolution[1].value.rv;
      this.courseProgressLoaded = true;
    }

    if (this.userStore.isXS001Subscriber) {
      await this.saveProgressForSkillsBuild();
    }
  };

  /**
   * Sends an event to the backend indicating that the user has entered the course.
   */
  @action sendCourseJoinEvent = async () => {
    const event = await postEvent('offering_enter', {
      data: {
        offering_slug: this.courseSlug,
        enrollment_uuid: this.euuid,
      },
    });

    return event;
  };

  /**
   * Saves the user's enrollment data for the current course. It saves the user's language preference and course settings.
   */
  @action saveEnrollmentUserData = async (language, settings) => {
    if (this.euuid) {
      if (language) {
        this.languageOverride = language;
      }

      if (settings) {
        this.offeringSettings = settings;
      }

      let { enrollment } = this;
      if (!enrollment) {
        enrollment = await getEnrollmentInstance(this.euuid);
      }

      let { user_data: oldUserData } = enrollment || {};

      if (!oldUserData) {
        oldUserData = {};
      }

      const newUserData = merge(oldUserData, {
        bookmark: this.pageSlug, // this saves the state for the last visited page
        settings: this.offeringSettings,
        ...(language && { language }),
      });

      const newEnrollment = await saveEnrollmentUserData(
        newUserData,
        this.euuid,
      );
      return newEnrollment;
    }
    return null;
  };

  @action resetInitialData = () => {
    this.courseSlug = null;
    this.courseInfo = null;
    this.courseInfoMeta = null;
    this.courseOffering = null;
    this.courseCode = null;
    this.courseVersion = null;
    this.courseCatalogEntry = null;
    this.translations = null;
    this.versions = [];

    this.euuid = null;
    this.enrollment = null;
  };

  @action resetComplemetaryData = () => {
    this.courseStructure = null;
    this.pageSlug = null;
    this.page = null;
  };

  /**
   * Fetches course info meta data.
   * Info meta data contains expert extras and lab plus list.
   */
  @action getCourseInfoMeta = async () => {
    try {
      const courseInfoMeta = await getCourseInfoMeta(this.courseSlug);
      this.courseInfoMeta = courseInfoMeta;
    } catch (e) {
      console.error('Failed getting course info meta', e);
    }
  };

  @action getCourseMetadata = async () => {
    let courseMetadata;
    try {
      courseMetadata = await getCourseMetadata(this.courseSlug);
      this.courseMetadata = courseMetadata;
    } catch (error) {
      console.error('Failed to get course metadata:', error);
      return null;
    }
    return courseMetadata;
  };

  /**
   * Main startup method for the course store.
   * This method is called when the course view is loaded.
   * It fetches all the necessary data for the course, except the data that is language dependent.
   * @param {string} slug course slug from url
   */
  @action getInitialData = async (slug) => {
    // This method depends on two things: A valid slug AND a loaded catalog in memory
    try {
      this.isLoadingInitialData = true;
      this.courseSlug = slug;

      const [courseInfo, courseOffering] = await Promise.all([
        getCourseInfo(this.courseSlug),
        getCourseOffering(this.courseSlug),
      ]);
      this.courseInfo = courseInfo;
      this.courseOffering = courseOffering;

      const { dynamic_info: dynamicInfo } = this.courseInfo || {};
      this.courseCode = dynamicInfo?.code;
      this.courseVersion = dynamicInfo?.current;
      this.translations = dynamicInfo?.variants?.[dynamicInfo?.current] || [];
      this.versions = Object.keys(dynamicInfo?.variants || []);

      this.courseCatalogEntry = this.catalogStore?.getCatalogEntryForCourse(
        this.courseCode,
        this.courseVersion,
      );

      if (!this.courseCatalogEntry) {
        throw new Error(`Course not found in catalog. courseSlug: ${slug}`);
      }

      if (!CourseStore.isBundle(this.courseCatalogEntry)) {
        this.localLabStore.getLabDefinition(this.courseSlug);

        this.getPageVideosForCourse(); // failure is not critical
        this.getCourseInfoMeta(); // failure is not critical

        const enrollmentInfo = await getCourseEnrollment(
          this.courseSlug,
          this.userStore.username,
        );

        const enrollment = await getEnrollmentInstance(enrollmentInfo?.uuid);
        this.enrollment = enrollment || {};
        this.euuid = enrollmentInfo?.uuid || this.euuid;

        this.classesStore.getClassesForCurrentUser();
        this.sendCourseJoinEvent();
      }

      if (this.courseCatalogEntry?.course_type === CourseType.Lesson) {
        this.getCourseMetadata();
      }

      this.loadedInitialData = true;
      this.initialLoadError = false;
      this.initialErrorCount = 0;
    } catch (e) {
      this.initialErrorCount += 1;
      console.error('Failed getting initial data for the course', e);
      reportError(
        e,
        {
          slug,
          loadedCourseInfo: !!this.courseInfo,
          loadedCourseOffering: !!this.courseOffering,
          loadedCatalogEntry: !!this.courseCatalogEntry,
        },
        'CourseViewV2.store.getInitialData',
        'error',
      );
      this.initialLoadError = true;
      this.resetInitialData();
    } finally {
      this.isLoadingInitialData = false;
    }
  };

  /**
   * Fetches the complementary data for the course based on the user's language.
   * This includes the course structure, the page content, etc
   */
  @action getComplementaryDataByLanguage = async () => {
    if (
      this.loadedInitialData &&
      this.courseSlug &&
      this.language &&
      !this.isBundledCourse
    ) {
      try {
        this.isLoadingComplementaryData = true;
        const structure = await this.getCourseStructure(); // used for ToC, progress and page navigation

        const pageURLParam = this.routerStore?.route?.params?.page;
        // prioritize para from url param over last bookmarked page
        const pageToLoad =
          pageURLParam ||
          CourseStore.getLastBookmarkedPage(
            this.courseSlug,
            this.enrollment,
            structure,
          );

        this.changePageContent(pageToLoad);

        this.complementaryDataLoadError = false;
        this.loadedComplementaryData = true;
      } catch (e) {
        reportError(
          e,
          {
            slug: this.courseSlug,
            language: this.language,
            loadedCourseStructure: !!this.courseStructure,
          },
          'CourseViewV2.store.getComplementaryDataByLanguage',
          'error',
        );
        console.error('Failed getting complementary data for the course', e);
        this.complementaryDataLoadError = true;
      } finally {
        this.isLoadingComplementaryData = false;
      }
    }
  };

  /**
   * Saves progress for specifically for SkillsBuild courses
   */
  @action saveProgressForSkillsBuild = async () => {
    const courseProgress = this.progressStore.getProgressForCourse(
      this.courseSlug,
    );

    if (
      courseProgress &&
      (this.progressStore.didJustEnrolledInCourse(courseProgress) ||
        this.progressStore.didJustFinishedCourse(courseProgress))
    ) {
      try {
        this.progressStore.setCourseProgressExternalSubscription(
          this.courseCode,
          courseProgress,
          this.userStore.subscription.subscription,
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  @action handleCurrentPageAsBookmarked = async () => {
    if (this.isCurrentPageBookmarked) {
      // remove
      await this.handleBookmark(this.pageSlug, undefined);
    } else {
      // add
      await this.handleBookmark(this.pageSlug, this.pageTitle);
    }
  };

  /**
   * Handles the bookmarking of a section in the course.
   * It will create a new bookmark if it doesn't exist, or remove it if it does.
   * @param {object} section
   * @param {string} title
   */
  @action handleBookmark = async (section, title) => {
    if (!section && !title) {
      return {};
    }
    try {
      const courseOffering = await createUpdateCourseBookmark(
        this.courseSlug,
        section,
        title,
      );

      this.courseOffering = courseOffering;
    } finally {
      return this.courseOffering;
    }
  };

  /**
   * Opens the feedback drawer for the current course.
   * It will send the course and page info to the feedback form.
   */
  @action openFeedbackDrawer = () => {
    const data = {
      course: this.courseCode,
      courseVersion: this.courseVersion,
      pageTitle: this.pageTitle,
      uuid: this.page?.uuid,
      url: window.location.href,
    };
    this.eventStore.toggleFeedbackForm(data);
  };

  @computed get enrollmentCore() {
    return this.enrollment?.core || {};
  }

  /**
   * Computes the course_info for the most recent version of the course
   */
  @computed get currentCourse() {
    return CourseStore.getCurrentCourse(this.courseInfo, this.language);
  }

  @computed get previousPageTag() {
    if (this.page?.prev_tag) {
      return this.page.prev_tag;
    }

    if (!this.courseStructure) {
      return null;
    }

    // if there is no prev_tag in the page, we need to find the previous page in the course structure
    const index = this.courseStructure.findIndex(
      (s) => s.page_tag === this.pageSlug,
    );
    return index > 0 ? this.courseStructure[index - 1].page_tag : null;
  }

  @computed get title() {
    return this.currentCourse?.title;
  }

  @computed get enrollmentNotFound() {
    return this.loadedInitialData && !this.euuid;
  }

  @computed get userData() {
    return this.enrollment?.user_data;
  }

  @computed get expertExtras() {
    return this.courseInfoMeta?.expert_extras;
  }

  @computed get hasExpertExtrasInfo() {
    return (
      this.courseInfoMeta?.doc_id === this.courseSlug &&
      this.expertExtras?.kaltura_playlists.length > 0
    );
  }

  @computed get modality() {
    return this.currentCourse?.modality;
  }

  @computed get courseType() {
    return this.courseCatalogEntry?.course_type;
  }

  @computed get isOpenSubscriberForCourse() {
    return this.userStore.isOpenSubscriberForCourse(this.courseCode);
  }

  @computed get isCourse() {
    return this.modality === Modality.Course;
  }

  @computed get isBundledCourse() {
    return CourseStore.isBundle(this.courseCatalogEntry);
  }

  @computed get isLesson() {
    return CatalogStore.isLesson(this.modality);
  }

  @computed get isLabPlus() {
    return CatalogStore.isLabPlus(this.courseType);
  }

  @computed get labPlusCourses() {
    return this.courseInfoMeta?.lab_plus;
  }

  @computed get hasLabPlusCourseInfo() {
    return (
      this.courseInfoMeta?.doc_id === this.courseSlug &&
      this.labPlusCourses?.code.length > 0
    );
  }

  @computed get isEarlyAccess() {
    const { dynamic_info: dynamicInfo } = this.courseInfo || {};
    return dynamicInfo?.summaries[0]?.status === 'early';
  }

  @computed get isSyndicationEnabled() {
    return !this.uiStore.isChineseInstance;
  }

  @computed get courseTotalProgress() {
    if (!this.courseProgress?.total_progress) {
      return null;
    }
    return Math.floor(this.courseProgress.total_progress * 100);
  }

  @computed get courseCompletionTime() {
    return this.courseProgress?.completion_time;
  }

  @computed get completionTime() {
    return this.enrollment?.completion_time;
  }

  @computed get epochVersions() {
    const { dynamic_info: dynamicInfo } = this.courseInfo || {};
    return dynamicInfo?.epoch_version_number || {};
  }

  @computed get sortedEpochVersions() {
    return Object.keys(this.epochVersions)
      .map((version) => {
        return [version, this.epochVersions[version]];
      })
      .sort((a, b) => {
        return a[1] - b[1];
      })
      .map((e) => {
        return e[0];
      });
  }

  @computed get sortedVersions() {
    // filter to exclude epoch versions that are in the product versions list and sort
    const sortedVersions = this.versions
      ?.filter((version) => !this.sortedEpochVersions?.includes(version))
      .map((a) =>
        a
          .split('.')
          .map((n) => +n + 100000)
          .join('.'),
      )
      .sort()
      .map((a) =>
        a
          .split('.')
          .map((n) => +n - 100000)
          .join('.'),
      );

    return sortedVersions?.concat?.(this.sortedEpochVersions) || [];
  }

  @computed get sortedVersionDesc() {
    return reverse([...this.sortedVersions]);
  }

  @computed get latestVersion() {
    if (!this.sortedVersions.length) {
      return null;
    }
    return this.sortedVersions.slice(-1)[0];
  }

  @computed get isLatestVersion() {
    return (
      this.latestVersion && this.currentCourse?.version === this.latestVersion
    );
  }

  @computed get progressStructure() {
    return this.courseProgress?.structure || [];
  }

  static isPageBlockedForFreeTier(pageTag) {
    return !/pr01|ch01|ch02/.test(pageTag);
  }

  @computed get isCurrentPageBlockedForFreeTier() {
    return (
      !this.isLoadingPage &&
      this.userStore.isFreeTierSubscriber &&
      CourseStore.isPageBlockedForFreeTier(this.pageSlug)
    );
  }

  isPageBlockedForFreeTierSubscriber = (pageTag) => {
    return (
      this.userStore.isFreeTierSubscriber &&
      CourseStore.isPageBlockedForFreeTier(pageTag)
    );
  };

  @computed get courseSections() {
    return (
      this.courseStructure?.filter((item) => item.type === 'section') || []
    );
  }

  @computed get currentPageNumber() {
    return (
      (this.courseSections?.findIndex(
        (item) => item.page_tag === this.pageSlug,
      ) || 0) + 1
    );
  }

  @computed get totalPageNumber() {
    return this.courseSections?.length || 0;
  }

  @computed get isNextButtonDisabled() {
    const nextPageTag = this.page?.next_tag;
    const isLastPage = this.currentPageNumber === this.totalPageNumber;
    return (
      this.isLoadingInitialData ||
      isLastPage ||
      this.isPageBlockedForFreeTierSubscriber(nextPageTag)
    );
  }

  @computed get isPrevButtonDisabled() {
    const prevPageTag = this.page?.prev_tag;
    const isFirstPage = this.currentPageNumber === 1;
    return (
      this.isLoadingInitialData ||
      isFirstPage ||
      this.isPageBlockedForFreeTierSubscriber(prevPageTag)
    );
  }

  @computed get content() {
    return this.page?.content?.html || '';
  }

  @computed get buildEnv() {
    return this.page?.build_env || {};
  }

  @computed get bookmarkList() {
    let bookmarkArray = [];

    if (this.courseOffering && this.courseOffering.bookmarks) {
      const { bookmarks } = this.courseOffering;
      bookmarkArray = bookmarks.filter((item, index) => {
        return bookmarks.indexOf(item) === index;
      });
    }

    return bookmarkArray;
  }

  @computed get isCurrentPageBookmarked() {
    return this.bookmarkList?.some(
      (bookmark) => bookmark.section === this.pageSlug,
    );
  }

  @computed get pageWithVideo() {
    const videosForCurrentPage = this.videosInCourse?.page_videos?.find(
      (v) => v.page_slug === this.pageSlug,
    )?.entries;

    const _defaultLanguage = 'en-US';
    const _currentLanguage = this.language || _defaultLanguage;

    let videosForCurrentPageByLanguage =
      videosForCurrentPage?.length > 0 &&
      videosForCurrentPage?.filter((v) => v.language === _currentLanguage);
    videosForCurrentPageByLanguage =
      videosForCurrentPageByLanguage?.length > 0
        ? videosForCurrentPageByLanguage
        : videosForCurrentPage?.filter((v) => v.language === _defaultLanguage);

    return videosForCurrentPage?.length > 0
      ? (videosForCurrentPageByLanguage.length > 0 &&
          videosForCurrentPageByLanguage[0]) ||
          videosForCurrentPage[0]
      : null;
  }

  @computed get playListId() {
    if (!this.expertExtras) {
      return null;
    }

    const playlists = this.expertExtras.kaltura_playlists;
    const result = playlists.filter((obj) => {
      return obj.playlist_name === 'main';
    });
    return result[0].id;
  }

  // UI related checks:
  @computed get videoWidgetTitle() {
    const trancuate = (anyTitle, maxLength) => {
      return anyTitle?.length > maxLength
        ? `${anyTitle?.slice(0, maxLength - 1)} ...`
        : anyTitle;
    };
    const trancuatedCourseTitle = trancuate(this.title, 70);
    const trancuatedSectionTitle = trancuate(this.pageTitle, 70);

    return ` - ${trancuatedCourseTitle} | ${trancuatedSectionTitle}`;
  }

  @computed get canShowToolbar() {
    return !this.userStore.isFreeTierSubscriber;
  }

  @computed get canShowToolbarMenu() {
    return (
      this.loadedInitialData &&
      !this.isOpenSubscriberForCourse &&
      !this.userStore.isLS101Subscriber &&
      !this.userStore.isExternalSubscriber &&
      !this.userStore.isFreeTierSubscriber
    );
  }

  @computed get canShowExpertExtras() {
    return this.hasExpertExtrasInfo && !this.isOpenSubscriberForCourse;
  }

  @computed get canShowLabPlus() {
    return (
      this.hasLabPlusCourseInfo && this.catalogStore.subscriptionHasLabPlus
    );
  }

  @computed get canShowBundledCourseToolbar() {
    const showToolbar =
      this.loadedInitialData &&
      !this.userStore.isFreeTierSubscriber &&
      !this.isOpenSubscriberForCourse &&
      !this.userStore.isLS101Subscriber &&
      !this.userStore.isExternalSubscriber;

    return showToolbar;
  }

  @computed get showSurvey() {
    // eslint-disable-next-line camelcase
    const preference = this.userData?.settings?.show_survey50;
    if (preference !== undefined && preference !== null) {
      return preference;
    }
    return true;
  }

  @computed get canShowAutostart() {
    const preference = this.enrollment?.user_data?.settings?.show_autostart;
    if (preference !== undefined && preference !== null) {
      return preference;
    }
    return true;
  }

  @computed get is50reached() {
    return Number(this.courseTotalProgress) >= 50;
  }

  @computed get canShowSurveyDialog() {
    return (
      !this.userStore.isOpenSubscriber &&
      this.showSurvey &&
      this.is50reached &&
      !this.isLesson
    );
  }

  @computed get isEbookEnabled() {
    return !this.isLesson;
  }

  @computed get canShowBookshelf() {
    return !(this.isLabPlus || this.isEarlyAccess);
  }

  @computed get bookshelfURL() {
    return `${this.configStore.ebooksPlatformURL}ebook/${this.courseSlug}`;
  }

  @computed get isFreeToolbar() {
    return (
      this.isOpenSubscriberForCourse ||
      this.userStore.isFreeTierSubscriber ||
      this.isLS101Subscriber ||
      this.isExternalSubscriber
    );
  }

  @computed get canShowVideoPlayer() {
    return (
      !this.userStore.isOpenSubscriber &&
      !this.isCurrentPageBlockedForFreeTier &&
      this.pageWithVideo
    );
  }

  @computed get customVideoPlayerId() {
    return this.videosInCourse?.custom_player_id;
  }

  @computed get canShowCertAttendanceButton() {
    return (
      !this.userStore.isOpenSubscriber &&
      !this.isLesson &&
      this.progressStore.shouldShowLegacyCertificateOfAttendance(
        Number(this.courseTotalProgress),
        this.courseCompletionTime,
      )
    );
  }

  @computed get legacyAttendanceTimestamp() {
    if (this.canShowCertAttendanceButton) {
      return dateToTimestamp(new Date());
    }

    return this.completionTime;
  }

  @computed get pageTitle() {
    return this.page?.title || '';
  }

  @computed get canShowOverviewTab() {
    return this.isLesson;
  }

  @computed get canShowLabPlusTab() {
    return (
      this.hasLabPlusCourseInfo && this.catalogStore.subscriptionHasLabPlus
    );
  }

  @computed get canShowCourseCompletionNotification() {
    return !this.canShowCertAttendanceButton && !this.isLesson;
  }
}

export default CourseStore;

export const courseContext = createContext(null);
