import React, { useContext, useState, useCallback, ReactNode } from 'react';
import { Checkbox, TextInput } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import useOverallProgressFilters from './useOverallProgressFilters';

import './styles.scss';

interface FilterGroupProps {
  children: ReactNode;
  onFilterChange?: (filters: Record<string, any>) => void;
  onTextFilterChange?: (value?: string) => void;
  hideEmptyFilters?: boolean;
}

interface FilterContextType {
  hideEmptyFilters?: boolean;
  currentFilters: Record<string, any>;
  setCurrentFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setFilterOption: (
    filterTypeKey: string,
    option: string,
    isChecked: boolean,
  ) => void;
}

const FilterContext = React.createContext<FilterContextType | undefined>(
  undefined,
);
FilterContext.displayName = 'FilterContext';

interface FilterGroupComponent extends React.FC<FilterGroupProps> {
  Filter: typeof Filter;
}

const FilterGroup: FilterGroupComponent = ({
  children,
  onFilterChange = () => {},
  onTextFilterChange = undefined,
  hideEmptyFilters = true,
}) => {
  const { t } = useTranslation();
  const filterRef = React.useRef<HTMLInputElement>(null);
  const [filterValue, setFilterValue] = useState('');
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>({});

  const handleClear = () => {
    setCurrentFilters({});
    onFilterChange({});
    setFilterValue('');
    onTextFilterChange?.('');
  };

  const setFilterOption = useCallback(
    (filterTypeKey: string, option: string, isChecked: boolean) => {
      setCurrentFilters((prev) => {
        const nextValue = {
          ...prev,
          [filterTypeKey]: {
            ...(prev[filterTypeKey] || {}),
            [option]: isChecked,
          },
        };
        onFilterChange(nextValue);
        return nextValue;
      });
    },
    [onFilterChange],
  );

  const handleFilterChange = (_event, value: string) => {
    setFilterValue(value);
    onTextFilterChange?.(value);
  };

  return (
    <FilterContext.Provider
      value={{
        currentFilters,
        setCurrentFilters,
        setFilterOption,
        hideEmptyFilters,
      }}
    >
      <div className="filter-group">
        <div className="filter-group__header">
          <span className="filter-group__title">{t('Filter')}</span>
          <button
            className="filter-group__clear-btn"
            type="button"
            onClick={handleClear}
          >
            {t('Clear')}
          </button>
        </div>
        {onTextFilterChange && (
          <div className="filter-group__text-filter-container">
            <p id="filter-by-offering-title">{t('Search by title')}</p>
            <TextInput
              aria-labelledby="filter-by-offering-title"
              ref={filterRef}
              value={filterValue}
              onFocus={(e) => e.target?.select()}
              onChange={handleFilterChange}
              placeholder={t('Type in offering title...')}
              aria-label={t('Filter by offering title')}
            />
          </div>
        )}
        <div className="filter-group__content">{children}</div>
      </div>
    </FilterContext.Provider>
  );
};

function useFilter() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a <FilterGroup />');
  }
  return context;
}

interface FilterProps {
  /**
   * If true, options with the same label will be grouped together. If one of the options is selected, all options with the same label will be selected.
   */
  groupEqualLabels?: boolean;
  /**
   * If true, the filter group will be expanded by default.
   */
  defaultOpen?: boolean;
  /**
   * The key of the filter type. This key should match the key used in the filter object from mobx store.
   */
  filterTypeKey: string;
  /**
   * The title/label of the filter group.
   */
  title: string;
  /**
   * An array of objects that restricts the options to be displayed. The key should match the key of the filter option.
   */
  restrictOptionsTo?: {
    key: string;
    extraControl?: React.ReactNode;
  }[];
}

const Filter: React.FC<FilterProps> = observer(
  ({
    filterTypeKey,
    title,
    restrictOptionsTo = [],
    defaultOpen = false,
    groupEqualLabels = false,
  }) => {
    const { t } = useTranslation();
    const { currentFilters, setFilterOption, hideEmptyFilters } = useFilter();
    const [isExpanded, setExpanded] = useState(defaultOpen);
    const filterOptions = useOverallProgressFilters(filterTypeKey);
    let restrictedOptions = filterOptions;

    if (Array.isArray(restrictOptionsTo) && restrictOptionsTo.length > 0) {
      restrictedOptions = filterOptions.filter((f) =>
        restrictOptionsTo.some((rule) => rule.key === f.key),
      );
    }

    if (
      hideEmptyFilters &&
      (!restrictedOptions || restrictedOptions.length === 0)
    ) {
      return null;
    }

    let optionElements = [];

    if (groupEqualLabels) {
      // Group options with the same label. If a groupped option is selected, all options with the same label will be selected.
      const groupedOptions = restrictedOptions.reduce((acc, item) => {
        if (!acc[item.value]) {
          acc[item.value] = [];
        }
        acc[item.value].push(item);
        return acc;
      }, {});

      optionElements = Object.keys(groupedOptions).map((label) => {
        const items = groupedOptions[label];
        const isChecked = items.every(
          (item) => currentFilters[filterTypeKey]?.[item.key],
        );

        return (
          <div key={label} className="filter-group-filter__option">
            <Checkbox
              isChecked={isChecked}
              id={`filter-option-${filterTypeKey}-${label}`}
              name={label}
              onChange={(e, value) => {
                items.forEach((item) => {
                  setFilterOption(filterTypeKey, item.key, value);
                });
              }}
              label={label}
            />
            <small className="filter-control-badge">
              {items.reduce((acc, item) => acc + item.count, 0)}
            </small>
          </div>
        );
      });
    } else {
      optionElements = restrictedOptions.map((item) => {
        const extraControl = restrictOptionsTo?.find(
          (rule) => rule.key === item.key,
        )?.extraControl;

        return (
          <div>
            <div key={item?.key} className="filter-group-filter__option">
              {item?.value !== 'Video Classroom' && (
                <>
                  <Checkbox
                    isChecked={currentFilters[filterTypeKey]?.[item.key]}
                    id={`filter-option-${filterTypeKey}-${item?.key}`}
                    name={item?.key}
                    onChange={(e, value) => {
                      setFilterOption(filterTypeKey, item?.key, value);
                    }}
                    label={item?.value}
                  />
                  <small className="filter-control-badge">{item?.count}</small>
                </>
              )}
            </div>
            {extraControl && <div>{extraControl}</div>}
          </div>
        );
      });
    }

    return (
      <div className="filter-group-filter">
        <div
          className="filter-group-filter__header"
          role="button"
          tabIndex={0}
          onClick={() => setExpanded((prev) => !prev)}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setExpanded((prev) => !prev);
            }
          }}
        >
          <p className="filter-group-filter__title">{title}</p>
          <div className="filter-group-filter__arrow-icon">
            <SVG src={isExpanded ? CollapseIcon : ExpandIcon}>
              {isExpanded ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
        </div>
        <ul
          className={`filter-group-filter__options ${
            isExpanded && 'filter-group-filter__options--expanded'
          }`}
        >
          {optionElements}
        </ul>
      </div>
    );
  },
);

FilterGroup.Filter = Filter;

export default FilterGroup;
