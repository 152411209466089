import { computed, observable } from 'mobx';
import { VocabularyNamespaces } from 'types';
import { removeSessionData } from '../services/StorageService';

class UIStore {
  i18nInstance = null;

  @observable _title = '';

  @observable currentLanguage = 'en-US';

  @observable languages = [];

  @observable theme = 'default';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get title() {
    return this._title;
  }

  @computed get isChineseInstance() {
    const { configStore } = this.rootStore;
    return configStore.rezodePlatform === 'china';
  }

  set title(title) {
    this._title = title;
  }

  onLanguageChange = async (language = 'en-US') => {
    const { vocabularyStore } = this.rootStore;

    this.currentLanguage = language;

    try {
      await vocabularyStore.getVocabularyList(language);

      const languages = await vocabularyStore.getVocabularyByNamespace(
        VocabularyNamespaces.Languages,
        language,
      );

      this.languages = languages || this.languages;
      return this.languages.find((lang) => lang.key === language);
    } catch (e) {
      console.error(e);
    }
    return this.currentLanguage;
  };

  clearLabAutostartSettings = () => {
    removeSessionData('showLabAutostart');
  };
}

export default UIStore;
