/**
 * @file Provides methods to interact with course data
 * @author Joshua Jack <jjack@redhat.com>
 */

import { get, put, post } from 'axios';
import queryString from 'query-string';

import {
  COURSES_API,
  COURSE_COLLATERAL_API,
  COURSE_INFO_SIMPLE_API,
  COURSE_INFO_WITH_LABS_API,
  COURSE_PAGE,
  COURSE_STRUCTURE_API,
  FREE_TIER_VC_API,
  USER_OFFERING_API,
  KALTURA_SESSION_API,
  COURSE_METADATA_API,
} from '../config/constants';

/**
 * Returns course list (does not require authentication or an enrollment)
 * @return {Object} An object containing user enrollment data
 */
export const getCourses = () =>
  new Promise((resolve, reject) => {
    get(COURSES_API)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Returns course summary (does not require authentication or an enrollment)
 * @param {string} code - Course code
 * @param {string} version - Course version
 * @return {Object} An object containing course summary data
 */
export const getCourseSummary = (code, version) => {
  let slug = code;

  if (version) {
    slug += `-${version}`;
  }

  return new Promise((resolve, reject) => {
    get(COURSE_INFO_SIMPLE_API.replace('<course-slug>', slug))
      .then((res) => {
        const course = res.data || {};

        if (!course) {
          return reject();
        }

        return resolve(course);
      })
      .catch((error) => reject(error));
  });
};

/**
 * Returns course info (does not require authentication or an enrollment)
 * @param {string} code - Course code
 * @param {string} version - Course version
 * @param {string} lang - Language
 * @return {Object} An object containing course info data
 */
export const getCourseInfo = (code, version, lang = 'en-US') =>
  new Promise((resolve, reject) => {
    getCourseSummary(code, version, lang)
      .then((course) => {
        if (!course) {
          return reject();
        }

        const summaries = course.summaries || [];

        version = version || course.latest;

        const result = summaries.find(
          (variant) => variant.language === lang && variant.version === version,
        );

        return resolve(result);
      })
      .catch((error) => reject(error));
  });

/**
 * Returns course structure (toc)
 * @param {string} courseSlug - Course slug
 * @param {string} lang - Language
 * @return {Object} An object containing course structure (toc) data
 */
export const getCourseStructure = (courseSlug, lang = 'en-US') =>
  new Promise((resolve, reject) => {
    get(
      `${COURSE_STRUCTURE_API.replace(
        '<course-slug>',
        courseSlug,
      )}?lang=${lang}`,
    )
      .then((res) => {
        resolve(res.data?.data?.attributes);
      })
      .catch((error) => reject(error));
  });

/**
 * Returns course page
 * @param {string} courseSlug - Course slug
 * @param {string} lang - Language
 * @return {Object} An object containing course page data
 */
export const getCoursePage = (courseSlug, pageSlug, lang = 'en-US') => {
  const api = COURSE_PAGE.replace('<course-slug>', courseSlug).replace(
    '<page>',
    pageSlug,
  );

  return new Promise((resolve, reject) => {
    get(`${api}?lang=${lang}`)
      .then((result) => {
        const { attributes } = result.data?.data || {};
        resolve(attributes);
      })
      .catch((e) => reject(e));
  });
};

/**
 * Returns course collateral for a specific course code, version and language
 * @param {string} code - Course code
 * @param {string} version - Course version
 * @param {string} lang - Language
 * @return {Object} An object containing course info data
 */
export const getCourseCollateral = async (code, version, lang = 'en-US') => {
  if (!code || !version) {
    throw new Error('Course code and version are required');
  }

  /*
   * tempoary sting. force version and lang.  course collateral will
   * be for the code, not the versionsed slug, I think.
   * language is a server bug which will be resolved
   * --bowe
   */
  version = '1';

  try {
    const collateral = await get(
      `${COURSE_COLLATERAL_API}${code}@@${version}@@${lang}`,
    );
    return collateral.data;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Returns course info
 * @param {string} code - Course code
 * @param {string} product - Product ('rol' or 'vt')
 * @return {Object} An object containing course info data
 */
export const getCourseAndLabInfo = (slug, product = 'rol') => {
  const api = COURSE_INFO_WITH_LABS_API.replace('<course-slug>', slug).replace(
    '<product>',
    product,
  );

  return new Promise((resolve, reject) => {
    get(api)
      .then((res) => {
        const course = res.data || {};

        if (!course) {
          return reject();
        }

        return resolve(course);
      })
      .catch((error) => reject(error));
  });
};

/**
 * Returns Free Tier video classroom info
 * @param {string} code - Course code
 * @return {Object} An object containing VC info data
 */
export const getFreeTierVCInfo = (code) => {
  return get(`${FREE_TIER_VC_API}${code}/`)
    .then((res) => {
      const course = res.data || {};

      if (!course) {
        throw new Error();
      }

      return course;
    })
    .catch((error) => new Error(error));
};

/**
 * Returns user course offering info
 * @param {string} slug - Course code
 * @return {Object} An object containing course offering info data
 */
export const getCourseOffering = async (slug) => {
  try {
    let courseOffering = await get(
      `${USER_OFFERING_API}/${slug}?modality=course`,
    );
    courseOffering = courseOffering.data || {};
    return courseOffering;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * add/delete boormark and returns user course offering info
 * to add data={"section":"ch01","title":"Chapter 1 Getting Started.."}
 * to delete data={"section":"ch01"}
 * @param {string} slug - Course code
 * @return {Object} An object containing course offering info data
 */
export const createUpdateCourseBookmark = async (slug, section, title) => {
  const data = { section, title };
  try {
    let courseOffering = await put(
      `${USER_OFFERING_API}/${slug}/bookmark`,
      data,
    );
    courseOffering = courseOffering.data || {};
    return courseOffering;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Returns Kaltura session id as string
 * @return {string} A String containing Kaltura Session ID
 */
export const getKalturaSession = async () => {
  try {
    const session = await post(KALTURA_SESSION_API);
    return session?.data;
  } catch (error) {
    throw error;
  }
};

export const getCourseMetadata = async (slug) => {
  try {
    const response = await get(`${COURSE_METADATA_API}${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCourseMetadataBySlugList = async (slugList = []) => {
  try {
    // example /sapi/zdocr/course_metadata/@@search?course_slugs=rh294-9.0&course_slugs=rh199-9.0
    const response = await get(`${COURSE_METADATA_API}@@search`, {
      params: { course_slugs: slugList },
      paramsSerializer: (params) => {
        return queryString.stringify(params, { arrayFormat: 'repeat' });
      },
    });

    return response.data?.items || [];
  } catch (error) {
    throw error;
  }
};

export const getCourseMetadataForCurrentSubscription = async () => {
  try {
    const response = await get(
      `${COURSE_METADATA_API}@@get_for_current_subscription`,
    );
    return response.data?.items || [];
  } catch (error) {
    throw error;
  }
};
