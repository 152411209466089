import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CenteredLoadingSpinner,
  Table,
  useFeature,
} from '@ole-ui/ole-ui-components';
import FilterGroup from 'components/FilterGroup';
import CourseNameCell from './CourseNameCell';

import './styles.scss';

const paginationOptions = {
  widgetId: 'management-dashboard-course-progress-pagination-options-menu',
  perPageOptions: [
    { title: '5', value: 5 },
    { title: '10', value: 10 },
    { title: '25', value: 25 },
  ],
};

interface CourseProgressProps {
  isLoading: boolean;
  progressPerCourse: Array<any>;
  setFilters: (filters: any) => void;
  setTextFilter: (text: string) => void;
}

const CourseProgress: React.FC<CourseProgressProps> = ({
  isLoading,
  progressPerCourse,
  setFilters,
  setTextFilter,
}) => {
  const { t } = useTranslation();
  const newTaxonomiesEnabled = useFeature({
    section: 'catalog',
    flagName: 'new_taxonomies',
    defaultFlagValue: false,
  });

  const columnDefinitions = useMemo(
    () => [
      {
        Header: t('Course'),
        accessor: 'course_title',
        Cell: CourseNameCell,
      },
      {
        Header: t('Completed'),
        accessor: 'completed',
        patternflyThProps: { modifier: 'fitContent', textAlign: 'center' },
      },
      {
        Header: t('In progress'),
        accessor: 'in_progress',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('Started'),
        accessor: 'started',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('Not started'),
        accessor: 'not_started',
        patternflyThProps: { modifier: 'fitContent' },
      },
    ],
    [t],
  );

  if (!progressPerCourse) return null;

  if (isLoading) return <CenteredLoadingSpinner />;

  return (
    <div className="course-progress">
      <div className="course-progress__filters">
        <FilterGroup
          onFilterChange={(filters) => {
            setFilters(filters);
          }}
          onTextFilterChange={(text) => {
            setTextFilter(text);
          }}
          hideEmptyFilters
        >
          <FilterGroup.Filter
            filterTypeKey="modalities"
            defaultOpen
            title={t('Delivery Formats')}
            restrictOptionsTo={[
              {
                key: 'module',
              },
              {
                key: 'course',
                // extraControl: <div>TODO: Toggle to Include courses with lesson activity</div>,
              },
            ]}
          />
          {newTaxonomiesEnabled ? (
            <>
              {/* NEW taxonomies: */}
              <FilterGroup.Filter
                filterTypeKey="taxonomyProducts"
                title={t('Products')}
              />
              <FilterGroup.Filter
                filterTypeKey="taxonomyTopics"
                title={t('Topics')}
              />
              <FilterGroup.Filter
                filterTypeKey="taxonomyAudiences"
                title={t('Audiences')}
              />
              <FilterGroup.Filter
                filterTypeKey="level"
                title={t('Level')}
                groupEqualLabels
              />
            </>
          ) : (
            <>
              {/* Legacy */}
              <FilterGroup.Filter
                filterTypeKey="products"
                title={t('Products')}
              />
              <FilterGroup.Filter
                filterTypeKey="categories"
                title={t('Categories')}
              />
            </>
          )}
        </FilterGroup>
      </div>
      <div className="course-progress__table-container">
        <Table
          ariaLabel={t(
            'Table containing breakdown of course usage and completion by the users at your organization',
          )}
          // @ts-ignore
          className="course-progress__table"
          data={progressPerCourse}
          columns={columnDefinitions}
          paginationOptions={paginationOptions}
          isStriped
        />
      </div>
    </div>
  );
};

export default CourseProgress;
