/* eslint-disable import/prefer-default-export */
function isNumeric(n) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Extracts the code and version from a slug. If the slug does not contain a version, it will return only the code. Version has to be a numeric value after an hyphen.
 * @param slug a string in the format code-version like 'ocp-architecture-4.12' or 'rh124-8.0'
 * @returns an object with the code and version like { code: 'ocp-architecture', version: '4.12' }
 */
export const extractFromSlug = (slug, throwError = false) => {
  if (typeof slug !== 'string') {
    if (throwError) {
      throw new Error(
        'Cannot extract from invalid slug. Slug should be a string in the format code-version like "ocp-architecture-4.12", "rh124-8.0" or even just "ocp-architecture" or "rh124" when there is no version.',
      );
    }
    return {};
  }

  const lastIndexOfDash = slug?.lastIndexOf?.('-');

  if (lastIndexOfDash === -1) {
    return { code: slug };
  }

  const version = slug.substring(lastIndexOfDash + 1);
  const code = slug.substring(0, lastIndexOfDash);

  if (isNumeric(version)) {
    return { code, version };
  }
  return { code: `${code}-${version}` };
};

export const toSlug = (code, version) => {
  if (version) {
    return `${code}-${version}`;
  }
  return code;
};

/**
 * Truncates a text if it's longer than a specified limit and, if so, replaces that longer substring with an ellipsis.
 * @param text any string
 * @param max the maximum number of characters to allow before an ellispis.
 * @returns the truncated string
 * @example truncateWithEllipses('Red Hat', 10) === 'Red Hat'
 * truncateWithEllipses('Red Hat', 5) === 'Red H...'
 */
export const truncateWithEllipses = (text: string, max: number) =>
  text.substring(0, max - 1) + (text.length > max ? '...' : '');
