/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/**
 * @file Course Catalog store
 * @author Joshua Jack <jjack@redhat.com>
 */

import { action, computed, observable } from 'mobx';
import Fuse from 'fuse.js';
import deepmerge from 'deepmerge';
import isEmpty from 'lodash/isEmpty';

// import lodash like this to guarantee tree shaking:
import lodashValues from 'lodash/values';
import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import intersectionBy from 'lodash/intersectionBy';
import lodashGet from 'lodash/get';

import {
  CatalogEntry,
  CourseType,
  Modality,
  OfferingCodePrefix,
  OfferingVisibility,
  VocabularyNamespaces,
} from 'types';
import { extractFromSlug } from 'helpers/utils';
import { TFunction } from 'i18next';
import { getCatalog, getCatalogEntries } from '../services/CatalogService';
import VocabularyStore from './Vocabulary';

// These fields are used to extract the categories and products
// from the catalog entries. The first element is prioritized over the second,
// and so on.
export const TAXONOMIES_PRIORITIES = {
  legacy: {
    categories: ['metadata.categories', 'categories'],
    products: ['metadata.products', 'products'],
  },
  new: {
    topics: ['metadata.taxonomies.topics', 'taxonomies.topics'],
    audiences: ['metadata.taxonomies.audiences', 'taxonomies.audiences'],
    products: ['metadata.taxonomies.products', 'taxonomies.products'],
    level: ['metadata.level', 'level'], // the only one outside of taxonomies
  },
};

type CatalogFilters = {
  earlyAccess?: string[];
  categories?: string[];
  languages?: string[];
  modalities?: string[];
  products?: string[];
  roles?: string[];
  taxonomyProducts?: string[];
  taxonomyAudiences?: string[];
  taxonomyTopics?: string[];
  level?: string[];
};

class CatalogStore {
  static isGreaterVersion(v1, v2) {
    return (
      v1
        .split('.')
        .map((n) => +n + 100000)
        .join('.')
        .localeCompare(
          v2
            .split('.')
            .map((n) => +n + 100000)
            .join('.'),
        ) > 0
    );
  }

  /**
   * Given an array of values, returns an array of objects with the following properties:
   * @param {Array} values - an array of filter values to be counted for each filter option
   * @param {Array} vocabulary - an array of objects with display_name and token properties
   */
  static renderFilters(values, vocabulary = []) {
    const count = countBy(values);

    const filters = Object.keys(count).reduce((filtered, entry) => {
      const foundVocabulary = vocabulary.find((item) => entry === item.token);

      if (!foundVocabulary) return filtered;

      filtered.push({
        key: foundVocabulary.token,
        value: foundVocabulary.display_name || `label_${foundVocabulary.token}`,
        count: count[foundVocabulary.token],
        checked: false,
        ...(foundVocabulary.expression && {
          expression: foundVocabulary.expression,
        }),
      });

      return filtered.sort((a, b) => b.count - a.count);
    }, []);
    return filters;
  }

  static composeCatalogGroups(catalogEntries: CatalogEntry[]) {
    // Construct a dict of all offerings by course code and version, omitting video classrooms
    const versionedEntries = catalogEntries
      .filter(
        (entry) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, entry) => {
        if (!dict[entry.code]) {
          dict[entry.code] = {};
        }
        const translation = entry.translations || [];

        dict[entry.code][entry.version] = deepmerge(entry, {
          translations: [
            {
              language: entry.language,
              title: entry.title,
            },
            ...translation,
          ],
        });
        return dict;
      }, {});

    // construct a dict of all video classrooms, sorted by course code
    const versionedVCs = catalogEntries
      .filter(
        (entry) =>
          entry.modality === Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, vc) => {
        if (!dict[vc.code]) {
          dict[vc.code] = {};
        }
        dict[vc.code][vc.version] = {};

        const translation = vc.translations || [];
        dict[vc.code][vc.version].videoClassroom = deepmerge(vc, {
          translations: [
            {
              language: vc.language,
              title: vc.title,
            },
            ...translation,
          ],
        });
        return dict;
      }, {});

    // deepmerge courses
    return deepmerge(versionedEntries, versionedVCs);
  }

  static isLocked(offeringVisibility: OfferingVisibility) {
    return (
      offeringVisibility === OfferingVisibility.Upgrade ||
      offeringVisibility === OfferingVisibility.Hidden
    );
  }

  static isEarlyAccess(offeringCode: string) {
    return !!offeringCode?.endsWith(OfferingCodePrefix.EarlyAccess);
  }

  static isLabPlus(offeringType: CourseType) {
    return offeringType === CourseType.LabPlus;
  }

  static isLesson(offeringType: CourseType) {
    return offeringType === CourseType.Lesson;
  }

  /**
   *  Prioritizes a field in an entry based on an array of fields.
   * @param {CatalogEntry} entry  - the entry to prioritize the field for
   * @param {Array} fields  - an array of fields to prioritize
   * @param {Boolean} isArray - if true, the field is expected to be an array
   * @returns  the prioritized field value
   */
  static prioritizeField = (
    entry: unknown,
    fields: string[],
    isArray: boolean,
  ) => {
    for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i];
      const value = lodashGet(entry, field);
      if (isArray) {
        if (Array.isArray(value) && value.length > 0) {
          return value;
        }
      } else if (value) {
        return value;
      }
    }
    return isArray ? [] : null;
  };

  @observable loaded: boolean = false;

  @observable subscriptionCatalog = [];

  @observable allCatalogEntries: CatalogEntry[] = [];

  @observable filterInfoLoaded = false;

  /**
   * Each element in the array consists of an object with the following properties:
   * - checked: bool
   * - count: number
   * - key: string
   * - value: string
   * Meaning that the filters are preprocessed and ready to be used in the UI, including the count.
   */
  @observable filters: CatalogFilters = {
    categories: [],
    products: [],
    earlyAccess: [],
    languages: [],
    modalities: [],
    roles: [],
    // new taxonomies
    taxonomyProducts: [],
    taxonomyAudiences: [],
    taxonomyTopics: [],
    level: [],
  };

  @observable managementOverviewFilters: CatalogFilters = {
    categories: [],
    products: [],
    earlyAccess: [],
    languages: [],
    modalities: [],
    roles: [],
    // new taxonomies:
    taxonomyProducts: [],
    taxonomyAudiences: [],
    taxonomyTopics: [],
    level: [],
  };

  @observable expandedFilterGroups = new Set();

  @observable _filteredEntries: CatalogEntry[] = [];

  @observable searchResults = null;

  @observable.ref courseCollateral = [];

  @observable _currentPage: number = 1;

  @observable entriesPerPage: number = 10;

  @observable selectedByExternalSubscriber = '';

  @observable loading: boolean = false;

  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action searchEntries = (string) => {
    const options = {
      shouldSort: true,
      threshold: 0,
      tokenize: true,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['slug', 'title', 'modality', 'course_type'],
    };

    if (!string.length) {
      this.searchResults = this.filteredEntries;
      return;
    }

    const fuse = new Fuse(this.filteredEntries, options);
    this.searchResults = fuse.search(string);
  };

  @computed get query() {
    return this.rootStore.searchStore.query;
  }

  @computed get totalPages(): number {
    return Math.ceil(this.filteredEntries.length / this.entriesPerPage) || 1;
  }

  @computed get currentPage(): number {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get filterKeys(): string[] {
    return Object.keys(this.filters);
  }

  /**
   * Returns an unique array of catalog entries, preferring the latest version
   */
  @computed get uniqueCatalogEntries() {
    const translations = {};

    // Construct a dic of unique catalog entries without VCs, preferring the latest version
    const uniqueDict = this.subscriptionCatalog
      .filter(
        (entry) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, entry) => {
        if (
          !dict[entry.code] ||
          CatalogStore.isGreaterVersion(entry.version, dict[entry.code].version)
        ) {
          dict[entry.code] = entry;
          if (entry.translations) {
            // accumulate the title translations
            translations[entry.code] = translations[entry.code]
              ? deepmerge(translations[entry.code], entry.translations)
              : entry.translations;
          }
        }
        return dict;
      }, {});

    // construct a dict of unique video classrooms, preferring the latest version
    const uniqueVCs = Object.values(
      this.subscriptionCatalog
        .filter(
          (entry) =>
            entry.modality === Modality.VideoClassroom &&
            entry.visibility !== OfferingVisibility.Hidden,
        )
        .reduce((dict, vc) => {
          if (
            !dict[vc.code] ||
            CatalogStore.isGreaterVersion(vc.version, dict[vc.code].version)
          ) {
            dict[vc.code] = vc;
          }
          return dict;
        }, {}),
    );

    // update course records to include VCs as well
    uniqueVCs.forEach((vc: any) => {
      const record = uniqueDict[vc.code];
      if (!record) {
        uniqueDict[vc.code] = vc;
        return;
      }
      if (record.modality !== Modality.VideoClassroom) {
        uniqueDict[vc.code] = deepmerge(record, { videoClassroom: vc });
      }
    });

    Object.entries(uniqueDict).forEach(([code, entry]: any) => {
      const translation = translations[entry.code] || [];

      uniqueDict[code] = deepmerge(entry, {
        translations: [
          {
            language: 'en-US',
            title: entry.title,
          },
          ...translation,
        ],
      });
    });

    return uniqueDict;
  }

  @computed get entries(): Array<CatalogEntry> {
    return Object.values(this.uniqueCatalogEntries);
  }

  set entries(entries) {
    this.subscriptionCatalog = entries;
  }

  @computed get filteredEntries() {
    const checkedFiltersLength = flatMap(
      this.filterKeys.map((key) =>
        this.filters[key].filter((filter) => filter.checked),
      ),
    ).length;

    return checkedFiltersLength || this.query
      ? this._filteredEntries
      : this.entries;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.filteredEntries.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  /**
   * Flattens the filters for a given key and returns an array of the checked keys.
   */
  flattenFilters(key) {
    return this.filters[key]
      .filter((item) => item.checked)
      .map((item) => item.key);
  }

  // idea: offload this to a separate worker
  __getFilteredEntries = (
    entryList: Array<unknown>,
    filters: any = {}, // each key is a filter (category, product, modality ...) and each value is an array of strings with the allowed values. Filters only show up if they exist in vocabulary data.
    entryAcessor: (unknown) => CatalogEntry = (entry) => entry as CatalogEntry,
    entryCourseIdField = 'doc_id',
  ) => {
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );
    const filteredDataByFilter = []; // contains filtered data array for each filter

    if (filters.earlyAccess?.length) {
      const filteredByEarlyAccess = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;
        return filters.earlyAccess.includes(entry?.status);
      });
      filteredDataByFilter.push(filteredByEarlyAccess);
    }

    if (filters.languages?.length) {
      const filteredByLanguages = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;
        const languageMap = flatMap(entry.translations, 'language');
        const filtered = languageMap?.filter((item) =>
          filters.languages.includes(item),
        );
        return languageMap?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByLanguages);
    }

    if (filters.modalities?.length) {
      const filteredByModalities = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;
        const finalModalitiesFilter = [...filters.modalities];
        if (
          filters.modalities.length &&
          filters.modalities.includes(Modality.Course)
        ) {
          finalModalitiesFilter.push(Modality.VideoClassroom);
        }
        const entryModality =
          entry?.course_type === 'regular' ||
          (entry?.modality && !entry?.course_type)
            ? entry?.modality
            : '';
        return (
          finalModalitiesFilter.includes(entryModality) ||
          finalModalitiesFilter.includes(entry?.course_type)
        );
      });
      filteredDataByFilter.push(filteredByModalities);
    }

    if (filters.roles?.length) {
      const filteredByRoles = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;
        // FIXME: Waiting on the property name for 'Job Roles'
        const { job_roles: jobRoles } = entry;
        const filtered = jobRoles?.filter((item) =>
          filters.roles.includes(item),
        );
        return jobRoles?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByRoles);
    }

    // ========== Legacy filter taxonomies ==========
    if (filters.categories?.length) {
      const filteredByCategories = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;

        const entryCategories = CatalogStore.prioritizeField(
          entry,
          TAXONOMIES_PRIORITIES.legacy.categories,
          true,
        );

        const filtered = entryCategories?.filter((item) =>
          filters.categories.includes(item),
        );

        return entryCategories?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByCategories);
    }

    if (filters.products?.length) {
      const filteredByProducts = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;

        const entryProducts = CatalogStore.prioritizeField(
          entry,
          TAXONOMIES_PRIORITIES.legacy.products,
          true,
        );

        const filtered =
          entryProducts?.filter((item) => filters.products.includes(item)) ||
          [];

        return entryProducts?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByProducts);
      // causing intersection issue...
    }

    // ========== End of legacy filter taxonomies ==========

    if (newTaxonomiesEnabled) {
      if (filters.taxonomyTopics?.length) {
        const filteredByTopics = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryTopics = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.topics,
            true,
          );
          const filtered = entryTopics?.filter((item) =>
            filters.taxonomyTopics.includes(item),
          );

          return entryTopics?.some((item) => filtered.includes(item));
        });
        filteredDataByFilter.push(filteredByTopics);
      }

      if (filters.taxonomyAudiences?.length) {
        const filteredByAudiences = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryAudiences = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.audiences,
            true,
          );

          const filtered = entryAudiences?.filter((item) =>
            filters.taxonomyAudiences.includes(item),
          );

          return entryAudiences?.some((item) => filtered.includes(item));
        });
        filteredDataByFilter.push(filteredByAudiences);
      }

      if (filters.taxonomyProducts?.length) {
        const filteredByProducts = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryProducts = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.products,
            true,
          );

          const filtered =
            entryProducts?.filter((item) =>
              filters.taxonomyProducts.includes(item),
            ) || [];

          return entryProducts?.some((item) => filtered.includes(item));
        });
        filteredDataByFilter.push(filteredByProducts);
      }

      if (filters.level?.length) {
        const filteredByLevels = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryLevel = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.level,
            false,
          );

          return filters.level.includes(`${entryLevel}`);
        });
        filteredDataByFilter.push(filteredByLevels);
      }
    }

    return filteredDataByFilter.length
      ? intersectionBy(...filteredDataByFilter, entryCourseIdField) // combine intersecting the result of all filters to get final filtered entries
      : entryList;
  };

  @action filterEntries = () => {
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );

    let filtersByType: CatalogFilters = {
      earlyAccess: this.flattenFilters('earlyAccess'),
      categories: this.flattenFilters('categories'),
      languages: this.flattenFilters('languages'),
      modalities: this.flattenFilters('modalities'),
      products: this.flattenFilters('products'),
      roles: this.flattenFilters('roles'),
    };

    if (newTaxonomiesEnabled) {
      filtersByType = {
        ...filtersByType,
        taxonomyProducts: this.flattenFilters('taxonomyProducts'),
        taxonomyAudiences: this.flattenFilters('taxonomyAudiences'),
        taxonomyTopics: this.flattenFilters('taxonomyTopics'),
        level: this.flattenFilters('level'),
      };
    }

    this._filteredEntries = this.__getFilteredEntries(
      this.entries,
      filtersByType,
    );

    if (this.query) {
      this.searchEntries(this.query);
      this._filteredEntries = this.searchResults;
    }
  };

  /**
   * Fetches the catalog entries for a given subscription.
   * @param {string} subscriptionCode - the subscription code to fetch the catalog entries for
   * @param {boolean} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getCatalogEntriesBySubscrition = async (
    subscriptionCode: string,
    force: boolean,
  ) => {
    if ((this.subscriptionCatalog.length || this.loading) && !force) {
      return this.subscriptionCatalog;
    }

    this.loading = true;
    try {
      const subscriptionEntries = await getCatalog(subscriptionCode);
      if (subscriptionEntries) {
        this.subscriptionCatalog = subscriptionEntries;
      }
    } finally {
      this.loading = false;
      this.loaded = true;
    }
    return this.subscriptionCatalog;
  };

  /**
   * Fetches the catalog entries for the current subscription.
   * @param {boolean} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getCatalogEntries = async (
    force: boolean,
  ): Promise<Array<CatalogEntry>> =>
    this.getCatalogEntriesBySubscrition(
      this.rootStore.userStore.subscription?.subscription,
      force,
    );

  /**
   * Fetches all catalog entries, independent from any subscription. Usually used for, but not limited to, alacarte users.
   * @param {*} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getAllCatalogEntries = async (
    force: boolean,
  ): Promise<Array<CatalogEntry>> => {
    if (this.allCatalogEntries.length && !force) {
      return this.allCatalogEntries;
    }

    this.loading = true;

    const allCatalogEntries: Array<CatalogEntry> = await getCatalogEntries();
    if (allCatalogEntries) {
      this.allCatalogEntries = allCatalogEntries;
    }

    this.loading = false;
    this.loaded = true;

    return this.allCatalogEntries;
  };

  /**
   * Returns a dictionary of all catalog entries grouped by course code and version.
   * Works for alacarte users.
   */
  @computed get groupedAllCatalogEntries() {
    return CatalogStore.composeCatalogGroups(this.allCatalogEntries);
  }

  /**
   * load the filters for the catalog entries. This is used to populate the filters in the UI.
   * This loads the vocabulary data for each filter type and then renders the filters based on
   * what is available in the the catalog entries. This ensures that filters only show up if they
   * exist in at least one catalog entry.
   */
  @action getFilterInfo = async (
    i18n: any,
    t: TFunction,
    vocabularyStore: VocabularyStore,
    customEntryList: Array<CatalogEntry>,
    isManagementOverview: boolean,
  ) => {
    this.filterInfoLoaded = false;
    const { language } = i18n;
    const { featuresStore } = this.rootStore;
    const { getVocabularyByNamespace } = vocabularyStore;
    const entryList: Array<CatalogEntry> =
      customEntryList || this.entries || [];
    const fieldName = isManagementOverview
      ? 'managementOverviewFilters'
      : 'filters';

    const newTaxonomiesEnabled: boolean = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );

    this[fieldName].earlyAccess = CatalogStore.renderFilters(
      flatMap(entryList, 'status'),
      [{ display_name: t('Early Access'), token: 'early' }],
    );

    const categoryLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.OfferingCategories,
      language,
    );

    this[fieldName].categories = CatalogStore.renderFilters(
      entryList
        .map((entry) =>
          CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.legacy.categories,
            true,
          ),
        )
        .flat(),
      categoryLabels,
    );

    const languageLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.Languages,
      language,
    );

    this[fieldName].languages = CatalogStore.renderFilters(
      flatMap(entryList, (key) =>
        key.translations?.map((translation) => translation?.language),
      ),
      languageLabels,
    );

    const modalityLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.Modalities,
      language,
    );
    const courseType = flatMap(entryList, (obj: CatalogEntry) =>
      obj.course_type ? obj.course_type : [],
    );

    const modalitiesInfo = flatMap(this.entries, (obj) => {
      if (obj.course_type && obj.course_type !== CourseType.Regular) {
        // prevent offering from counting as a course, since it will count via the "course_type" already
        return null;
      }

      return obj.modality;
    });

    const deliveryFormat = modalitiesInfo.concat(courseType);

    this[fieldName].modalities = CatalogStore.renderFilters(
      deliveryFormat,
      modalityLabels,
    );

    const productLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.OfferingProducts,
      language,
    );

    this[fieldName].products = CatalogStore.renderFilters(
      entryList
        .map((entry) =>
          CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.legacy.products,
            true,
          ),
        )
        .flat(),
      productLabels,
    );

    const roleLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.JobRoles,
      language,
    );

    this[fieldName].roles = CatalogStore.renderFilters(
      flatMap(entryList, 'job_roles'),
      roleLabels,
    );

    if (newTaxonomiesEnabled) {
      const topicLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyTopics,
        language,
      );

      this[fieldName].taxonomyTopics = CatalogStore.renderFilters(
        entryList
          .map((entry) =>
            CatalogStore.prioritizeField(
              entry,
              TAXONOMIES_PRIORITIES.new.topics,
              true,
            ),
          )
          .flat(),
        topicLabels,
      );

      const audienceLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyAudiences,
        language,
      );

      this[fieldName].taxonomyAudiences = CatalogStore.renderFilters(
        entryList
          .map((entry) =>
            CatalogStore.prioritizeField(
              entry,
              TAXONOMIES_PRIORITIES.new.audiences,
              true,
            ),
          )
          .flat(),
        audienceLabels,
      );

      const taxonomyProductLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyProducts,
        language,
      );

      this[fieldName].taxonomyProducts = CatalogStore.renderFilters(
        entryList
          .map((entry) =>
            CatalogStore.prioritizeField(
              entry,
              TAXONOMIES_PRIORITIES.new.products,
              true,
            ),
          )
          .flat(),
        taxonomyProductLabels,
      );

      const levelLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingLevel,
        language,
      );

      this[fieldName].level = CatalogStore.renderFilters(
        entryList
          .map((entry) =>
            CatalogStore.prioritizeField(
              entry,
              TAXONOMIES_PRIORITIES.new.level,
              false,
            ),
          )
          .flat(),
        levelLabels,
      );
    }

    this.filterInfoLoaded = true;
    return this[fieldName];
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @action clearCatalog = () => {
    this.subscriptionCatalog = [];
  };

  clearFilters = (exceptions = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        const skipped = exceptions.includes(item.key);

        if (!skipped) {
          item.checked = false;
        }
      });
    });

    this.filterEntries();
  };

  /**
   * Presets the filters based on an array of filter keys.
   * @param {Array} filters - an array of filter keys to preset to checked
   */
  presetFilters = (filters = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        if (filters.includes(item.key)) item.checked = true;
      });
    });

    this.filterEntries();
  };

  toggleFilterGroups = (id, expanded) =>
    expanded
      ? this.expandedFilterGroups.add(id)
      : this.expandedFilterGroups.delete(id);

  /**
   * Returns a dictionary of all catalog entries of a given subscription grouped by course code and version.
   * Works for subscription users only. Will be empty for alacarte users.
   */
  @computed get groupedCatalogEntries() {
    return CatalogStore.composeCatalogGroups(this.subscriptionCatalog);
  }

  subscriptionHasCourseType = (courseType) =>
    (this.subscriptionCatalog?.find(
      (entry) =>
        entry?.course_type === courseType &&
        entry?.visibility !== OfferingVisibility.Hidden,
    ) &&
      true) ||
    false;

  @computed get subscriptionHasLabPlus() {
    return this.subscriptionHasCourseType(CourseType.LabPlus);
  }

  @computed get subscriptionHasLesson() {
    return this.subscriptionHasCourseType(CourseType.Lesson);
  }

  @computed get groupedAlaCarteEnrollments() {
    const translations = {};
    const { classesStore } = this.rootStore;

    const uniqueDict = Object.values(classesStore.offerings)
      .filter(
        (entry: any) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict: any, entry: any) => {
        if (
          !dict[entry.slug] ||
          CatalogStore.isGreaterVersion(entry.version, dict[entry.slug].version)
        ) {
          dict[entry.slug] = entry;
          if (entry.translations) {
            // accumulate the title translations
            translations[entry.slug] = translations[entry.slug]
              ? deepmerge(translations[entry.slug], entry.translations)
              : entry.translations;
          }
        }
        return dict;
      }, {});

    // construct a dict of unique video classrooms, preferring the latest version
    const uniqueVCs = Object.values(
      Object.values(classesStore.offerings)
        .filter((entry: any) => entry.modality === Modality.VideoClassroom)
        .reduce((dict, vc: any) => {
          if (
            !dict[vc.code] ||
            CatalogStore.isGreaterVersion(vc.version, dict[vc.code].version)
          ) {
            dict[vc.code] = vc;
          }
          return dict;
        }, {}),
    );

    // update course records to include VCs as well
    uniqueVCs.forEach((vc) => {
      const record = uniqueDict[vc.code];
      if (!record) {
        uniqueDict[vc.code] = vc;
        return;
      }
      if (record.modality !== Modality.VideoClassroom) {
        uniqueDict[vc.code] = deepmerge(record, { videoClassroom: vc });
      }
    });

    return Object.values(uniqueDict);
  }

  @computed get groupedAlaCarteEnrollmentsByCode() {
    return mapValues(groupBy(this.groupedAlaCarteEnrollments, 'code'), (o) =>
      keyBy(o, 'version'),
    );
  }

  @computed get groupedAlaCarteEnrollmentsAsArray() {
    return lodashValues(this.groupedAlaCarteEnrollmentsByCode);
  }

  @computed get groupedAlaCarteCatalogEntriesAsArray() {
    const allowedToView = pickBy(this.groupedAllCatalogEntries, (value, key) =>
      Boolean(this.groupedAlaCarteEnrollmentsByCode[key]),
    );
    return lodashValues(allowedToView);
  }

  @action removeModalityFromCatalog(modalities) {
    if (!modalities) return;

    this.subscriptionCatalog = this.subscriptionCatalog?.filter(
      (entry) => !modalities.includes(entry.modality),
    );
  }

  @action getSortedVersions(code) {
    return this.subscriptionCatalog
      .filter((a) => a.code === code)
      .map((a) => a.version)
      .sort((a, b) => {
        const aa = a
          .split('.')
          .map((n) => +n + 100000)
          .join('.');
        const bb = b
          .split('.')
          .map((n) => +n + 100000)
          .join('.');
        if (aa < bb) return -1;
        if (aa > bb) return 1;
        return 0;
      });
  }

  @action getLatestVersion(code: string) {
    const versions = this.getSortedVersions(code);
    if (!versions.length) {
      return null;
    }

    return versions.slice(-1)[0];
  }

  getTranslatedCourseName(
    slugOrCode,
    locale = 'en',
    uniqueCatalogEntries = this.uniqueCatalogEntries,
  ) {
    const { code } = extractFromSlug(slugOrCode);
    const entry = uniqueCatalogEntries[code];
    const translatedName = entry?.translations?.find(
      (element) => element.language === locale,
    )?.title;

    return translatedName || entry?.title || code;
  }

  /**
   * Given a course code, returns any valid full slug (code + version) for the respective entry.
   * Useful for when we need the slug for linking to a course, but we don't know the version.
   * Does not guarantee the highest version.
   * @param {*} code course code without version
   * @param {*} uniqueCatalogEntries
   * @returns course slug (code + version)
   */
  getAnySlugFromCode(code, uniqueCatalogEntries = this.uniqueCatalogEntries) {
    if (code.includes('-')) {
      return code;
    }

    const entry = uniqueCatalogEntries[code];

    return entry.slug;
  }

  @computed get hasCatalogLoaded() {
    return !(
      isEmpty(this.groupedCatalogEntries) &&
      isEmpty(this.groupedAllCatalogEntries)
    );
  }

  getCatalogEntryForCourse(courseCode: string, courseVersion: string) {
    const code = `${courseCode}`.toLowerCase();

    if (
      this.rootStore.userStore?.isAlaCarte ||
      this.rootStore.userStore?.isOpenSubscriber
    ) {
      return this.groupedAllCatalogEntries?.[code]?.[courseVersion];
    }

    return (
      this.groupedCatalogEntries?.[code]?.[courseVersion] ||
      this.groupedAllCatalogEntries?.[code]?.[courseVersion]
    );
  }
}

export default CatalogStore;
