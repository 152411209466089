import React from 'react';
import { observer } from 'mobx-react';
import { Tabs, Tab } from '@patternfly/react-core';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { useFeature } from '@ole-ui/ole-ui-components';
import CourseProgressTable from './CourseProgressTable';
import ExamStatusTable from './ExamStatusTable';
import LabUsageTable from './LabUsageTable';
import SubscriptionTable from './SubscriptionTable';
import TotalHoursTable from './TotalHours';

import './styles.scss';

const UserMetrics = () => {
  const { t } = useTranslation();

  const isLessonTabEnabled = useFeature({
    section: 'mgmt_overview',
    flagName: 'lessons_tab',
    defaultFlagValue: false,
  });

  return (
    <React.Fragment>
      <Tabs
        defaultActiveKey="courseProgress"
        id="uncontrolled-tab-example"
        isFilled
        isBox
        className="user-metrics-tabs"
      >
        <Tab
          eventKey="courseProgress"
          title={t('Course Progress')}
          data-analytics-id="course-prog-tab-team-mngmt-dboard-lp"
        >
          <CourseProgressTable courseType="course" />
        </Tab>
        <Tab
          eventKey="lessonProgress"
          title={t('Lesson Progress')}
          data-analytics-id="lesson-prog-tab-team-mngmt-dboard-lp"
          isHidden={!isLessonTabEnabled}
        >
          <CourseProgressTable courseType="module" />
        </Tab>
        <Tab
          eventKey="todo1"
          title={t('Time Spent Studying')}
          data-analytics-id="time-spent-tab-team-mngmt-dboard-lp"
        >
          <TotalHoursTable />
        </Tab>
        <Tab
          eventKey="examStatus"
          title={t('Exam Status')}
          data-analytics-id="exam-tab-team-mngmt-dboard-lp"
        >
          <ExamStatusTable />
        </Tab>
        <Tab
          eventKey="labUsage"
          title={t('Lab Hours')}
          data-analytics-id="lab-hrs-tab-team-mngmt-dboard-lp"
        >
          <LabUsageTable />
        </Tab>
        <Tab
          eventKey="subscription"
          title={t('Subscription')}
          data-analytics-id="subscription-tab-team-mngmt-dboard-lp"
        >
          <SubscriptionTable />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(UserMetrics),
  GenericViewError,
  handleError,
);
