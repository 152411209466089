import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import compact from 'lodash/compact';
import { when } from 'mobx';
import useMobxStores from 'hooks/useMobxStores';

/**
 * This hook depends on both, catalog_entries API and usageSummary.user_progress_per_course API.
 * If the catalog_entries array does not contain the entry from the usageSummary.user_progress_per_course API,
 * then the entry is not considered for the filter. Also, the entry has to have the products field populated.
 */
function useOverallProgressFilters(key) {
  const { i18n, t } = useTranslation();
  const { catalogStore, vocabularyStore, organizationStore } = useMobxStores();

  useEffect(() => {
    when(
      () => organizationStore.userProgressPerCourses?.length > 0,
      () => {
        const func = async () => {
          // catalogEntriesWithProgress is a list of catalogEntries, but only the entries that have a given progress based on userProgress docs.
          const catalogEntriesWithProgress = compact(
            organizationStore.userProgressPerCourses.map(
              (e) => e.catalog_entry,
            ),
          );

          catalogStore.getFilterInfo(
            i18n,
            t,
            vocabularyStore,
            catalogEntriesWithProgress,
            true,
          );
        };

        func();
      },
    );
  }, [organizationStore.userProgressPerCourses]);

  return catalogStore?.managementOverviewFilters?.[key] || [];
}

export default useOverallProgressFilters;
